import React, { Suspense } from "react";
import ReactGA from "react-ga";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";

import Loading from "../components/loading";
import Routes from "./routes";
import { theme } from "../utils/theme";

ReactGA.initialize("G-N8N752RTYC", { standardImplementation: true });
const NavBar = React.lazy(() => import("../components/navbar/navbar"));
const Footer = React.lazy(() => import("../components/footer/footer"));

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Suspense fallback={<Loading />}>
          <NavBar />
          <Routes />
          <Footer />
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
