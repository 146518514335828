import { createTheme } from '@mui/material/styles'

const primaryColor = '#000000'
const secondaryColor = '#258AFF'
const tertiaryColor = '#F6EDFE'
const defaultTheme = createTheme()

export const theme = createTheme({
  typography: {
    fontFamily: 'Manrope'
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          borderRadius: 10,
          padding: '6px 30px',
          color: primaryColor,
          fontSize: defaultTheme.typography.pxToRem(16)
        },
        outlined: {
          border: '1px solid #101010',
          background: 'transparent',
          color: 'black'
        }
      }
    },
    MuiCssBaseline: {
      '@global': {
        '@font-face': ['Manrope']
      }
    }
  },
  palette: {
    primary: {
      main: primaryColor
    },
    secondary: {
      main: secondaryColor
    },
    tertiary: {
      main: tertiaryColor
    }
  }
})
